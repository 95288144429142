import { Box, Flex, Heading, Show, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoadingSpinner } from '../../../../../components/misc/LoadingSpinner';
import { CalculatorResponseModel } from '../../../../../lib/Calculator/Models/CalculatorResponseModel';

import { useInView } from 'react-intersection-observer';
import { MopspotText } from '../../../../../components/misc/MopspotText';
import { CleaningFrequency } from '../../../../../lib/Calculator/Models/CleaningFrequency';
import { getQuotesForCleaningType } from '../../../../../lib/Calculator/quoteService';
import { sendFormDataToHubspot } from '../../../../../lib/Hubspot/hubspot';
import { AddonForm } from '../AddonForm';
import { CleaningQuotes } from './CleaningQuote';

export type RemovedItem = {
  cleaningFrequency: CleaningFrequency;
  key: string;
};

export const QuoteStep = () => {
  const [calculatorResponse, setCalculatorResponse] = useState<CalculatorResponseModel[]>();
  const form = useFormContext();

  const [removedItems, setRemovedItems] = useState<RemovedItem[]>([]);

  const onQuoteFormChange = useCallback(async () => {
    await calculateQuote(removedItems);
  }, []);

  const calculateQuote = async (removedItemsArray: RemovedItem[]) => {
    const quotes = await getQuotesForCleaningType(form.getValues(), removedItemsArray);
    setCalculatorResponse(quotes);
    form.setValue('amount', quotes[0].totalCost.startingPrice);
    form.setValue('calculatorResponse', quotes);
    quotes.forEach((value) => {
      form.setValue(`Contact.CleaningQuote.${value.productName.replace(' ', '').toLowerCase()}_cost`, value.totalCost.startingPrice);
    });
    let removedItemsString = '';
    removedItemsArray.forEach((value) => {
      removedItemsString += `${value.key}, `;
    });
    form.setValue('Contact.CleaningQuote.ExcludedItems', removedItemsString);
    sendFormDataToHubspot(form.getValues(), form);
  };

  const onRemoveAddonFromQuote = (formKey: string, cleaningFrequency: CleaningFrequency) => {
    const newArray = [{ key: formKey, cleaningFrequency }, ...removedItems];
    setRemovedItems(newArray);
    calculateQuote(newArray);
  };

  const onRemoveAddonFromAll = (formKey: string) => {
    const newArray = removedItems.filter((value) => {
      return value.key === formKey;
    });
    setRemovedItems(newArray);
  };

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '0px'
  });

  useEffect(() => {
    calculateQuote(removedItems);
  }, []);

  return calculatorResponse == undefined ? (
    <LoadingSpinner />
  ) : (
    <>
      <Box>
        <Show above='lg'>
          <Heading>
            Your <MopspotText /> Cleaning quote
          </Heading>
          <Text size='xs' padding={'10px 0px 10px 0px'}>
            Manage, review, and adjust your quote summary.
          </Text>
        </Show>
        {!inView && (
          <Show below='lg'>
            <Box position={'fixed'} top={{ base: '55px' }} left={'0px'} w={'100%'} zIndex={9999}>
              <CleaningQuotes onRemoveAddon={onRemoveAddonFromQuote} quotes={[calculatorResponse[0]]} allCollapsed={true} />
            </Box>
          </Show>
        )}
        {!inView && <Box>hello</Box>}
        <Box bg='bg.surface' border={{ base: '0', lg: '1px' }} borderRadius='4px' borderColor={'black'} p={0}>
          <Flex backgroundColor={'#f7f7f7'} flexDir={{ base: 'column', lg: 'row' }}>
            <Box w={{ base: '100%', lg: '60%' }} h={'100%'}>
              <div ref={ref}>{<CleaningQuotes onRemoveAddon={onRemoveAddonFromQuote} quotes={calculatorResponse} />}</div>
            </Box>
            <Box w={{ base: '100%', lg: '40%' }} padding={{ base: '0', lg: '20px' }} paddingTop={{ base: '25px' }} textAlign={'center'} backgroundColor={'white'}>
              <Heading size='md'>Boost Your Shine</Heading>
              <Text paddingBottom={'20px'} size='sm'>
                Select additional services you would like to add to your quote.
              </Text>
              <Box w={'90%'} margin='auto'>
                <AddonForm onAddonChange={onQuoteFormChange} onAddonRemoved={onRemoveAddonFromAll} />
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
